let isLazyLoad = is_lazy_load ? false : true;
let rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
let rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
let rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }
                location.href = data + force;
            }
        },
    });
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    let selectId = $(this).attr('id');
    let selectedValue = $(this).val();

    if (selectId.includes("returnQuantity")) {
        valueIdTailleQuantity = selectId.split("returnQuantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#returnChoice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#returnChoice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#returnChoice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#returnChoice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("returnChoice")) {
        valueIdTailleChoice = selectId.split("returnChoice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#returnQuantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#returnQuantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#returnQuantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#returnQuantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#returnQuantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").removeClass('disabled');
    } else {
        $(".divBtnPrintPdf").addClass('disabled');
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});

// init swiper function for desktop and mobile. Instead of declaring multiple functions we use one function with selector and swiper options as parameter to properly init swiper.

function initializeSwiper(selector, options) {
    $(selector).each(function () {
        if ($(this).find('.swiper-slide').length > 1) {
            new Swiper(selector, options);
        }
    });
}

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {

    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {

    return str.replace(rex_script_tags, '');
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {

    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {

    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * Function used on quantity selector evolution
 */
function updateProductQuantity(action, prod_id) {
    let qte_stock,
        qte_input,
        plus_selector,
        minus_selector;

    // Recover html selectors
    plus_selector = $("#ligne_qte .qte_plus");
    minus_selector = $("#ligne_qte .qte_minus");

    // Get product stock quantity and input quantity selected values
    qte_stock = $('#product_stock_counter').val();
    qte_stock = (qte_stock != '') ? qte_stock : 0;
    qte_input = $('#ligne_qte .qte_selected').val();
    qte_input = (qte_input != '') ? qte_input : 0;

    let selectorDisabled = false;

    if (action === 'plus') {
        qte_input++;

        // Remove disabled classes if necessary
        if ((qte_input > 1) && (minus_selector.hasClass('selector_disabled'))) {
            minus_selector.removeClass('selector_disabled');
        }

        // Update form
        if (qte_input <= qte_stock) {
            $('#produt_qte_selected').val(qte_input);
        } else {
            qte_input--;
            plus_selector.addClass('selector_disabled');
            selectorDisabled = true;
        }

        if (qte_input == qte_stock) {
            plus_selector.addClass('selector_disabled');
            selectorDisabled = true;
        }

    } else if (action === 'minus') {
        qte_input--;

        // If qte stored in input can be decremented
        if ((qte_input > 1) || (qte_input == 1)) {
            $('#produt_qte_selected').val(qte_input);
        }

        // Pour débloque le + si clique sur le moins
        if (plus_selector.hasClass('selector_disabled')) {
            plus_selector.removeClass('selector_disabled');
        }

        // Add diabled class if needed
        if (qte_input == 1) {
            // $('#produt_qte_selected').val(qte_input);
            if (!minus_selector.hasClass('selector_disabled')) {
                minus_selector.addClass('selector_disabled');
                selectorDisabled = true;
            }
        }
    }

    // Update input holding stock counter to add security : if qte = 0 you can't add it to your cart
    if (!selectorDisabled) {
        getAvailableQuantity(prod_id, qte_input, 'product', '', '', false);
    }
}


function mySubmit(pType) {

    var form = document.getElementById("formPanier");

    form.action = create_link('order_basket');

    setTimeout(function () {

        form.submit();
    });
}


/**
 * Generate label for return order
 */
function showPdfEtiquetteRetour(orderId, multiTransp, returnNumber, show_pdf) {

    let data = {};

    if (!show_pdf) {
        data = $('#return_products_form_' + orderId).serialize();
        data += '&idOrder=' + orderId + '&multiTransp=' + multiTransp + '&return_number=' + returnNumber + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + orderId + '&multiTransp=' + multiTransp + '&return_number=' + returnNumber;
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {

                if (show_pdf) {
                    window.location.href = res;
                } else {

                    let response = ajax_file(path_relative_root + 'ajax_return_address.php?order_id=' + orderId + '&return_number=' + returnNumber);

                    if (response !== '') {
                        $('.popup_numero_retour').html(response);
                        // add a title on return & return coupon popup
                        $("<div class='return_popup_title'>" + Translator.translate('effectuer_retour') + "</div><div class='recommandation'>" + Translator.translate('return_pop_print_text') + "</div>").insertBefore("#printPdf");
                    } else {
                        location.reload();
                    }
                }
            }
        }
    });
}

// Close 2nd return popup after ajax loaded
function close_popup_return(obj_elem) {
    $("#shad").hide();
    $("#popup_numero_retour_" + obj_elem).hide();
    location.reload();
}

/**
 * Validate a return for an order
 */
function createEtiquetteRetour(orderId, multiTransp) {

    let has_errors = false;
    $(".return_quantity").each(function () {
        let return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {
        let returnNumber = ajax_file(path_relative_root + "ajax_num_etiquette.php?idOrder=" + orderId + "&multiTransp=" + multiTransp);
        showPdfEtiquetteRetour(orderId, multiTransp, returnNumber, false);
    }
}


//delete coupon from cart
function deleteCoupon() {
    $.ajax({
        type: 'get',
        data: {
            act: 'delcp'
        },
        url: path_relative_root + create_link('order_basket')
    });
}


/**
 * Add product to cart
 **/
function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var couleurProd = form_elm.couleurProd;
    var tailleProd = form_elm.tailleProd;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass("loading");
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, couleurProd, tailleProd);
    }
}

/**
 * Refresh cart page
 **/
function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur,
    couleurProd,
    tailleProd
) {
    if (i !== "") {
        i = "_" + i;
        prixU = $("#prixU" + i).val();
    }

    var couleurs = (typeof couleurProd != 'undefined' && couleurProd != null) ? couleurProd : $("#couleurProd" + i);
    var tailles = (typeof tailleProd != 'undefined' && tailleProd != null) ? tailleProd : $("#tailleProd" + i);
    var res = "";
    var flagError = false;

    if (tailles instanceof HTMLInputElement == false && tailles && tailles.val() === "" || tailles instanceof HTMLInputElement && tailles.value === "") {
        $("#sizeerror").slideDown();
        return false;

    } else if (couleurs instanceof HTMLInputElement == false && couleurs && couleurs.val() === "" || couleurs instanceof HTMLInputElement && tailles.value === "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value === ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);
        return false;
    } else {
        let qte = 0;
        if ($("#qteProd" + i).length) {
            qte = $("#qteProd" + i).val();
        } else if ($("#produt_qte_selected").length) {
            qte = $("#produt_qte_selected").val();
        }

        var idCouleur = (couleurs instanceof HTMLInputElement) ? couleurs.value : couleurs.val();
        var idTaille = (tailles instanceof HTMLInputElement) ? tailles.value : tailles.val();

        var array_response = [];
        var response = ajax_file(
            path_relative_root +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");
            array_response.push(qte);

            // If no stock
            if (array_response[1] === "false") {
                $("#size_" + idTaille).addClass("disabled");
                alert(Translator.translate("product_no_stock_2"));
                return false;
            }
        }

        return array_response;
    }
}

/**
 * Add product to cart
 **/
function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, couleurProd, tailleProd) {
    let id = '';
    let prix_id = 'prixU';

    if (from === 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, couleurProd, tailleProd);

    if (array_panier != false) {
        if (from === 'basket') {
            location.reload();
            return false;
        }

        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        // DATALAYER V2
        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];

            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }

            pushIntoDatalayer(dl_objet);
        }

        var evt = new CustomEvent("add_to_cart_success", {'detail': array_panier});
        window.dispatchEvent(evt);

    } else {
        var evt = new CustomEvent("add_to_cart_error");
        window.dispatchEvent(evt);
    }

    eval(toEval);

    $('.loader').removeClass('loading');
    return false;
}

/**
 * Add product to wishlist
 */
function addToWishlist() {

    let wishlistBtn = $(this);
    let product_id = $(this).data('product-id');
    let product_refext = $(this).data('product-external-ref');
    let color_id = $("#couleurProd").val();
    let size_id = $("#tailleProd").val();
    let need_color_id = ($("#couleurProd").length !== 0 && $("#couleurProd").val() !== 'none');
    let need_size_id = ($("#tailleProd").length !== 0 && $("#tailleProd").val() !== 'none');
    let wishlist = [];

    if (parseInt($('#in_basket').val()) === 1) {
        let id_select = $(this).attr('class');
        product_id = id_select.split('product_towish basketToWishlist id_');
        color_id = "#couleurProd_" + product_id;
        color_id = color_id.replace(',', '');
        color_id = $(color_id).val();
        size_id = "#tailleProd_" + product_id;
        size_id = size_id.replace(',', '');
        size_id = $(size_id).val();

        need_color_id = "#couleurProd_" + product_id;
        need_color_id = need_color_id.replace(',', '');
        need_color_id = $(need_color_id).val();
        need_size_id = "#tailleProd_" + product_id;
        need_size_id = need_size_id.replace(',', '');
        need_size_id = $(need_size_id).val();

        need_color_id = ($(need_color_id).length != 0 && $(need_color_id).val() != 'none');
        need_size_id = ($(need_size_id).length != 0 && $(need_size_id).val() != 'none');
    }

    let datas = {
        product_id: product_id,
        color_id: color_id,
        size_id: size_id,
        need_color_id: need_color_id,
        need_size_id: need_size_id,
    };

    // Check if the product is already in the wishlist

    if (!$(this).hasClass('existToWishlistButton')) {

        if (!product_id || typeof product_id == "undefined") {

            return false;
        }

        if (parseInt($('#in_basket').val()) === 1) {
            datas.product_id = datas.product_id[1];
        }

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), datas, function (resp) {

            $(".addToWishlistButton").parent().removeClass('loading');

            if (resp.error) {

                if (resp.message == "login_required") {
                    let html =
                        '<div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="wrapper_btn">' +
                        '<a class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</a>" +
                        "</div>" +
                        "</div>";

                    if ($('#lightbox_achat_express').length > 0) {
                        $('#lightbox_achat_express').fadeOut("fast", function () {
                            $('#shad').css('display', 'none');
                        });
                    }
                    alert(html);
                    $("#abox .form_submit").css("display", "none");
                } else if (resp.message != "error") {
                    $("#sizeerror").addClass('actif');
                } else {
                    alert(Translator.translate(resp.result.error_message));
                    return false;
                }
            } else {
                // Evenement DataLayer
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                        'products': {
                            'product_id': product_id,
                            'product_refext': product_refext,
                        }
                    });
                }

                if (parseInt($('#in_basket').val()) === 1) {
                    let btn_wish = "#addToWishlistButton_" + product_id;
                    btn_wish = btn_wish.replace(',', '');

                    $(btn_wish).addClass("existToWishlistButton");
                } else {
                    $(".addToWishlistButton").addClass("existToWishlistButton");
                    wishlist.push(resp.result.wishlist_row_array);
                }

            }
        });

    } else {
        datas.remove = true;

        $.ajax({
            url: path_relative_root + 'ajax_remove_from_wishlist.php',
            type: 'post',
            data: datas
        })
            .done(function (resp) {

                if (!resp.error) {
                    wishlistBtn.removeClass("existToWishlistButton");
                    wishlistBtn.data('wishlistproductid', '');
                    wishlistBtn.attr('data-wishlistproductid', '');
                }
            });
    }
}

/**
 * Allows you to retrieve stocks of sizes by color as well as their stock
 */
function setAvailableSizes() {
    let produit_id = $('#produit_principal').val();
    let data = {produit_id: new Array(produit_id)};

    if (typeof isGlobalEActive != 'undefined' && isGlobalEActive !== false) {
        data.warehouse_only = 1;
        data.isGlobalEActive = isGlobalEActive;
    }

    let elem_id = $(this).val();

    $.ajax({
        url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (res.success && res.result) {
                let sizes_tab = res.result[produit_id][elem_id];
                let size_box, is_disabled;

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        $("#btn_alert_stock").hide();
                        $(".product_info_wrapper #size_is_not_available").hide();
                        size_box = $('.product_info_wrapper [name="itm_size"]').filter(
                            '[value="' + size_id + '"]'
                        );
                        is_disabled = !sizes_tab[size_id];
                        if (is_disabled) {
                            size_box.addClass("disabled");
                        } else {
                            size_box.removeClass("disabled");
                        }
                    } else {
                        $("#btn_alert_stock").show();
                        $(".product_info_wrapper #size_is_not_available").show();
                    }
                }
            }
        }
    });
}

/**
 * Move out of stock products to the top of the cart // Override app 
 */
function moveProductOutOfStock() {

    if ($('.cart_product_line.out_of_stock').length) {
        $('.cart_product_line.out_of_stock').each(function () {
            $(this).appendTo('.cart_out_of_stock_table');
        });
    }
}

// function to init countdown in preheader
function countdownPreheader(timestamp) {
    const countdownDay = document.querySelector('[data-js="countdown-day"]');
    const countdownHour = document.querySelector('[data-js="countdown-hour"]');
    const countdownMinute = document.querySelector('[data-js="countdown-minute"]');
    const countdownSecond = document.querySelector('[data-js="countdown-second"]');
    const countdownEndtext = document.querySelector('[data-js="countdown-endtext"]');
    const countdownContainer = document.querySelector('[data-js="countdown"]');

    const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = timestamp - now;

        if (distance <= 0) {
            clearInterval(interval);
            countdownContainer.style.display = 'none';
            countdownEndtext.innerHTML = "";
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        countdownDay.innerHTML = days;
        countdownHour.innerHTML = hours;
        countdownMinute.innerHTML = minutes;
        countdownSecond.innerHTML = seconds;
    }, 1000);
}

const targetTimestamp = new Date("2024-10-13T22:00:00Z").getTime(); // should be the same date as the timestamp in the preheader

// a small timeout is needed to allow the preheader to load completely before the countdown (especially for mobile)
setTimeout(function () {
    countdownPreheader(targetTimestamp);
    $('.countdown-block').addClass('unblur'); // remove blur effect for countdown loading animation
}, 1700);